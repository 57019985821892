<template>
	<div class="body">
		<navTop></navTop>
		<div class="wrap">
			<div class="wrap-left">
				<menuLeft :active="menuIndex"></menuLeft>
			</div>
			<div class="wrap-right">
				<div class="wrap-right-header">
					<div>{{ $t("applyInvoice.gongpinMall") }}</div>
					<div>{{ $t("applyInvoice.orderTime") }}:{{orderTime}}</div>
				</div>
				<div class="wrap-right-body">
					<table class="tab-header" border="0">
						<tr><td colspan="4" style="height: 10px;"></td></tr>
						<tr>
							<td colspan="2" width="50%" style="text-align: left;padding-left: 20px;">{{$t('userCenter.goodsInfo')}}</td>
							<td width="20%">{{$t('applyInvoice.type')}}</td>
							<td width="20%">{{$t('userCenter.price')}}</td>
							<td style="text-align: right;padding-right: 30px;">{{$t('userCenter.number')}}</td>
						</tr>
						<tr><td colspan="4" style="height:10px;"></td></tr>
					</table>
					<table class="tab-body" border="0" v-for="(item,index) in orderGoods" cellpadding="0" cellspacing="0">
						<tr v-for="goods in item.goodsInfo">
							<td width="15%"><img :src="goods.img" width="80px" height="80px"></td>
							<td width="35%" style="text-align: left;">
							<div style="line-height:24px;">{{$lang(goods,"goodsInfo")}}</div>
							<div style="color:#888;margin-top:5px;">{{$lang(goods,"spec")}}</div>
							</td>
							<td width="20%">{{goods.type}}</td>
							<td width="20%">￥{{goods.price}}</td>
							<td>{{goods.number}}</td>
						</tr>
						<tr>
							<td colspan="5" style="height: 10px;"></td>
						</tr>
						<tr>
							<td colspan="3" style="text-align: left;background-color: #eee;color:#888;">
								<el-checkbox v-model="checked" style="margin-left: 25px;">{{ $t("operation.select") }}</el-checkbox>
								<span style="margin:0 90px;"><el-link @click="navTo('/userhome/ordercenter/detail')">{{$t('applyInvoice.getOrderDetail')}}</el-link></span>
							    <span style="color: #888;margin-right:5px;">{{ $t('applyInvoice.subtotal') }}</span>
								<span style="color:red;font-size: 16px;">￥{{ item.subtotal }}</span>
							</td>
							<td colspan="2" style="background-color: #eee;color:#888;"><el-button type="primary" size="mini" @click="navTo('/userhome/applyinvoice/editinvoice')">{{$t('applyInvoice.applyInvoice')}}</el-button></td>
						</tr>
					</table>
				</div>
				<div style="height:30px;"></div>
				<div class="wrap-right-header">
					<div>{{ $t("applyInvoice.designRecharge") }}</div>
					<div>{{ $t("applyInvoice.orderTime") }}:{{rechareTime}}</div>
				</div>
				<div class="wrap-right-body">
					<table class="tab-header" border="0">
						<tr><td colspan="4" style="height: 10px;"></td></tr>
						<tr>
							<td style="text-align: left;padding-left: 20px;">{{$t('userCenter.goodsInfo')}}</td>
							<td>{{$t('applyInvoice.type')}}</td>
							<td>{{$t('userCenter.price')}}</td>
							<td>{{$t('userCenter.number')}}</td>
						</tr>
						<tr><td colspan="4" style="height: 10px;"></td></tr>
					</table>
					<table class="tab-body" border="0" v-for="(item,index) in orderDesign" cellpadding="0" cellspacing="0">
						<tr v-for="goods in item.goodsInfo">
							<td style="text-align: left;padding:40px 20px;width:40%;">
								<div>{{$lang(goods,"designRecharge")}}</div>
							</td>
							<td>{{goods.type}}</td>
							<td>￥{{goods.price}}</td>
							<td>{{goods.number}}</td>
						</tr>
						<tr>
							<td colspan="2" class="tb-bgcolor" style="text-align:left;padding-left:20px;"><el-checkbox v-model="checked" style="background-color: #eee;color:#888;">选择</el-checkbox></td>
							<td class="tb-bgcolor">
								<span style="color: #888;margin-right:5px;">{{ $t("applyInvoice.subtotal") }}</span>
								<span style="color:red;font-size: 16px;">￥{{ item.subtotal }}</span>
							</td class="tb-bgcolor">
							<td class="tb-bgcolor"><el-button type="primary" size="mini">{{$t('applyInvoice.applyInvoice')}}</el-button></td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import menuLeft from "@/components/MenuLeft";
	import navTop from "@/components/navTop";
	export default{
		components: {menuLeft,navTop},
		data(){
			return{
				menuIndex:'4',
				checked:true,
				orderTime:"2020-09-08 15:24:46",
				rechareTime:"2020-09-08 15:24:46",
				orderGoods:[
					{
					subtotalText:{
						subtotalZhCn: "小计",
						subtotalEnUs: "Subtotal"
					},
					subtotal:'12800.00',
					payTimeText:{
						payTimeZhCn:"交易时间：",
						payTimeEnUs:"Pay time："
					},
					payTime:'2020-06-09 09:24:34',
					goodsInfo: [{
						img:'https://',
						goodsInfoZhCn: '小型列管换热器 无菌换热器卫生及换热器微型换热器微通道换热器',
						goodsInfoEnUs:'Small tube heat exchanger aseptic heat exchanger sanitary heat exchanger miniature heat exchanger microchannel heat exchanger',
						specZhCn:'商品规格：FY-15ZDPIC',
						specEnUs:'Goods Specs：FY-15ZDPIC',
						type: '微通道换热器',
						price: '12800.00',
						number:'1'
					  }]
					}
				],
				orderDesign:[
					{
					subtotalText:{
						subtotalZhCn: "小计",
						subtotalEnUs: "Subtotal"
					},
					subtotal:'12800.00',
					payTimeText:{
						payTimeZhCn:"交易时间：",
						payTimeEnUs:"Pay time："
					},
					payTime:'2020-06-09 09:24:34',
					
					goodsInfo: [{
						img:'https://',
						goodsInfoZhCn: '小型列管换热器 无菌换热器卫生及换热器微型换热器微通道换热器',
						goodsInfoEnUs:'Small tube heat exchanger aseptic heat exchanger sanitary heat exchanger miniature heat exchanger microchannel heat exchanger',
						specZhCn:'商品规格：FY-15ZDPIC',
						specEnUs:'Goods Specs：FY-15ZDPIC',
						designRechargeZhCn:"设计点充值",
						designRechargeEnUs:"Design point recharge",
						type: '微通道换热器',
						price: '12800.00',
						number:'1'
					  }]
					}
				]
			}
		},
		methods:{
			handleOpen(key, keyPath) {
		         console.log(key, keyPath);
		       },
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			navTo(url){
				//console.log(this.$route);
				if(url){
					this.$router.push(url);
				}
			},
		}
		
	}
</script>
<style  scoped>
	.body{}
	.nav{
		margin: 0 auto;
		width:1200px;
		margin-top: 40px;
		padding:20px 0;
		
	}
	.nav span{
		marign:0px 10px;
	}
	.el-link{
		font-size: 16px;
		font-weight: 600;
	}
	.wrap{
		margin: 0 auto;
		width:1200px;
		display: flex;
		justify-content: space-between;
	}
	.wrap-left{

		width:20%;
		min-height:800px;
		background-color: #fff;
		padding:20px 15px 40px;
	}
	
	.wrap-right{
		width:78%;
		min-height:800px;
		background-color: #fff;
		font-size:12px;
	}
	
	.wrap-right-header{
		display: flex;
		justify-content: space-between;
		background-color: #555;
		color:#fff;
		padding:10px 30px;
		font-size: 14px;
	}
	
	.tab-header{
		width:100%;
	}
	.tab-header tr td{
		font-size: 14px;
		text-align: center;
		color: #888;
	}
	.tab-body{
		
		width: 100%;
	}
	.tab-body tr td{
		font-size: 14px;
		padding:5px 0px;
		text-align: center;
	}
	.tb-bgcolor{
		background-color: #eee;color:#888;
	}

</style>
